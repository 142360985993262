<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g id="icon-metamask">
		<g id="Group 3">
			<path
				id="Vector"
				d="M20.9749 2.40002L13.0945 8.23101L14.5599 4.79462L20.9749 2.40002Z"
				fill="#E17726"
				stroke="#E17726"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				id="Vector_2"
				d="M2.79785 2.40002L10.6081 8.28543L9.21291 4.79461L2.79785 2.40002Z"
				fill="#E27625"
				stroke="#E27625"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				id="Vector_3"
				d="M18.1375 15.92L16.0408 19.1232L20.5305 20.3594L21.8167 15.9901L18.1375 15.92Z"
				fill="#E27625"
				stroke="#E27625"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				id="Vector_4"
				d="M1.96362 15.9901L3.24195 20.3594L7.72392 19.1232L5.63492 15.92L1.96362 15.9901Z"
				fill="#E27625"
				stroke="#E27625"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				id="Vector_5"
				d="M7.48224 10.509L6.23511 12.3905L10.6781 12.5926L10.53 7.81122L7.48224 10.509Z"
				fill="#E27625"
				stroke="#E27625"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				id="Vector_6"
				d="M16.2903 10.509L13.1958 7.75684L13.0945 12.5927L17.5374 12.3905L16.2903 10.509Z"
				fill="#E27625"
				stroke="#E27625"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				id="Vector_7"
				d="M7.72412 19.1232L10.4133 17.8249L8.09828 16.0212L7.72412 19.1232Z"
				fill="#E27625"
				stroke="#E27625"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				id="Vector_8"
				d="M13.3596 17.8249L16.041 19.1232L15.6746 16.0212L13.3596 17.8249Z"
				fill="#E27625"
				stroke="#E27625"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				id="Vector_9"
				d="M16.041 19.1234L13.3596 17.825L13.5778 19.5665L13.5544 20.3051L16.041 19.1234Z"
				fill="#D5BFB2"
				stroke="#D5BFB2"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				id="Vector_10"
				d="M7.72412 19.1234L10.2184 20.3051L10.2028 19.5665L10.4133 17.825L7.72412 19.1234Z"
				fill="#D5BFB2"
				stroke="#D5BFB2"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				id="Vector_11"
				d="M10.2649 14.8705L8.03564 14.2175L9.61016 13.4944L10.2649 14.8705Z"
				fill="#233447"
				stroke="#233447"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				id="Vector_12"
				d="M13.5076 14.8705L14.1623 13.4944L15.7447 14.2175L13.5076 14.8705Z"
				fill="#233447"
				stroke="#233447"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				id="Vector_13"
				d="M7.72397 19.1232L8.11373 15.92L5.63501 15.9901L7.72397 19.1232Z"
				fill="#CC6228"
				stroke="#CC6228"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				id="Vector_14"
				d="M15.6589 15.92L16.0409 19.1232L18.1377 15.9901L15.6589 15.92Z"
				fill="#CC6228"
				stroke="#CC6228"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				id="Vector_15"
				d="M17.5374 12.3904L13.0945 12.5925L13.5076 14.8705L14.1624 13.4944L15.7447 14.2174L17.5374 12.3904Z"
				fill="#CC6228"
				stroke="#CC6228"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				id="Vector_16"
				d="M8.03568 14.2174L9.6102 13.4944L10.265 14.8705L10.6781 12.5925L6.23511 12.3904L8.03568 14.2174Z"
				fill="#CC6228"
				stroke="#CC6228"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				id="Vector_17"
				d="M6.23535 12.3904L8.0983 16.0212L8.0359 14.2174L6.23535 12.3904Z"
				fill="#E27525"
				stroke="#E27525"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				id="Vector_18"
				d="M15.7447 14.2174L15.6746 16.0212L17.5375 12.3904L15.7447 14.2174Z"
				fill="#E27525"
				stroke="#E27525"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				id="Vector_19"
				d="M10.6783 12.5925L10.2651 14.8706L10.7874 17.5605L10.9043 14.0153L10.6783 12.5925Z"
				fill="#E27525"
				stroke="#E27525"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				id="Vector_20"
				d="M13.0945 12.5925L12.8762 14.0076L12.9854 17.5605L13.5076 14.8706L13.0945 12.5925Z"
				fill="#E27525"
				stroke="#E27525"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				id="Vector_21"
				d="M13.5076 14.8706L12.9854 17.5606L13.3595 17.825L15.6746 16.0212L15.7447 14.2175L13.5076 14.8706Z"
				fill="#F5841F"
				stroke="#F5841F"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				id="Vector_22"
				d="M8.03564 14.2175L8.09804 16.0212L10.413 17.825L10.7872 17.5606L10.2649 14.8706L8.03564 14.2175Z"
				fill="#F5841F"
				stroke="#F5841F"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				id="Vector_23"
				d="M13.5546 20.3051L13.5779 19.5665L13.3753 19.3954H10.3977L10.2028 19.5665L10.2184 20.3051L7.72412 19.1233L8.59712 19.8386L10.3665 21.0592H13.3986L15.1758 19.8386L16.041 19.1233L13.5546 20.3051Z"
				fill="#C0AC9D"
				stroke="#C0AC9D"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				id="Vector_24"
				d="M13.3595 17.8248L12.9853 17.5605H10.7872L10.4131 17.8248L10.2026 19.5664L10.3975 19.3953H13.3751L13.5778 19.5664L13.3595 17.8248Z"
				fill="#161616"
				stroke="#161616"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				id="Vector_25"
				d="M21.3102 8.61194L21.9727 5.39326L20.975 2.40002L13.3596 8.03666L16.2904 10.509L20.4294 11.7141L21.3414 10.6489L20.9439 10.3612L21.5752 9.78596L21.0919 9.41276L21.7233 8.93072L21.3102 8.61194Z"
				fill="#763E1A"
				stroke="#763E1A"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				id="Vector_26"
				d="M1.80005 5.39326L2.47039 8.61194L2.04169 8.93072L2.68085 9.41276L2.19758 9.78596L2.82895 10.3612L2.43142 10.6489L3.3434 11.7141L7.48241 10.509L10.4132 8.03666L2.79777 2.40002L1.80005 5.39326Z"
				fill="#763E1A"
				stroke="#763E1A"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				id="Vector_27"
				d="M20.4293 11.714L16.2903 10.509L17.5375 12.3905L15.6746 16.0212L18.1377 15.9901H21.8168L20.4293 11.714Z"
				fill="#F5841F"
				stroke="#F5841F"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				id="Vector_28"
				d="M7.48224 10.509L3.34328 11.714L1.96362 15.9901H5.63492L8.09802 16.0212L6.23511 12.3905L7.48224 10.509Z"
				fill="#F5841F"
				stroke="#F5841F"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				id="Vector_29"
				d="M13.0944 12.5926L13.3594 8.03662L14.5598 4.79462H9.21265L10.413 8.03662L10.678 12.5926L10.7794 14.0231L10.7872 17.5606H12.9853L12.9931 14.0231L13.0944 12.5926Z"
				fill="#F5841F"
				stroke="#F5841F"
				stroke-width="0.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
	</g>
</svg>
